import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonFooter,
} from "@ionic/react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";
import Story from "../components/Story";
import { useHistory, useParams } from "react-router";
import { audioPlayer, getStoryById, StoriesContext } from "../logic/stories";
import { useContext } from "react";

const StoryPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { storyId } = useParams();
  const { addToStoriesCompleted } = useContext(StoriesContext);

  const story = getStoryById(storyId);
  const setCompletedStory = () => {
    if (story.rootStory) {
      addToStoriesCompleted(story.rootStory._ref);
    } else {
      addToStoriesCompleted(storyId);
    }

    history.push("/search-story");
  };

  // Return blank page if needed arguments are null
  if (!story) return <IonPage></IonPage>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dragon-skin-background">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Age of Dragons</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="body-background">
        <Story story={story} />
      </IonContent>
      <IonFooter className="ion-padding ion-text-center dragon-skin-background">
        <div>
          {story.type === "result" && (
            <IonButton
              className="ion-margin-end"
              onClick={setCompletedStory}
              color="primary"
            >
              {t("requirements-met")}
            </IonButton>
          )}
          <IonButton
            onClick={() => {
              history.push("/search-story");
              audioPlayer("stop");
            }}
            color="light"
          >
            {t("leave-the-region")}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default StoryPage;
